<template>
  <div class="appMenu">
    <div class="weather_map">
      <div class="icon">
        <template v-for="(item, index) in menu">
          <img :key="item.id" :src="[currentIndex == index ? item.aIcon : item.icon]" width="50" height="50"
            @click="getData(item, index)" />
        </template>

        <!-- <img :src="tyButton" width="50" height="50" @click="getTyList" />
        <img :src="rader" width="50" height="50" @click="getRader" />
        <img :src="winds" width="50" height="50" @click="getWind" />
        <img :src="wave" width="50" height="50" @click="getWave" />
        <img :src="cloud" width="50" height="50" @click="getCloud" /> -->
      </div>
    </div>
    <map-view ref="mapView" :style="{ width: '100%', height: '100%', position: 'absolute' }" />
  </div>
</template>
<script>
import MapView from "@/views/map/index.vue";

export default {
  name: "AppMenu",
  components: {
    MapView,
  },
  computed: {
    visible() { return this.$store.state['map/mapMenu'].isVisible }
  },
  data() {
    return {
      currentIndex: 6,
      menu: [
        {
          id: 1,
          name: "台风",
          icon: require("../../assets/image/map/typhoon/typhoon.png"),
          aIcon: require("../../assets/image/map/typhoon/atyphoon.png"),
        },
        {
          id: 2,
          name: "云图",
          icon: require("../../assets/image/map/typhoon/cloud.png"),
          aIcon: require("../../assets/image/map/typhoon/acloud.png"),
        },
        {
          id: 3,
          name: "雷达",
          icon: require("../../assets/image/map/typhoon/rander.png"),
          aIcon: require("../../assets/image/map/typhoon/arander.png"),
        },
        {
          id: 4,
          name: "浪场",
          icon: require("../../assets/image/map/typhoon/wave.png"),
          aIcon: require("../../assets/image/map/typhoon/awave.png"),
        },
        {
          id: 5,
          name: "风场",
          icon: require("../../assets/image/map/typhoon/wind.png"),
          aIcon: require("../../assets/image/map/typhoon/awind.png"),
        },
      ],
    };
  },
  created() { },
  mounted() { },
  destroyed() { },
  watch: {
    visible(newVal, oldVal) {
      //对数据执行操作
      console.log(newVal, oldVal)
      if (newVal) {
        this.currentIndex = 0
      } else {
        this.currentIndex = -3
      }
    }
  },
  methods: {
    getTyList() {
      this.$store.dispatch(
        "map/mapTyphoon/setSelected",
        Object.assign({ type: 3 })
      );

    },
    getRader() {
      this.$store.dispatch("map/mapOther/setSelected", {
        type: "rander",
        checked: true,
      });
    },
    getWind() {
      this.$store.dispatch("map/mapWeather/setSelected", {
        type: "wind",
        checked: true,
      });
    },
    getWave() {
      this.$store.dispatch("map/mapWeather/setSelected", {
        type: "current",
        checked: true,
      });
    },
    getCloud() {
      this.$store.dispatch("map/mapOther/setSelected", {
        type: "cloud",
        checked: true,
      });
    },

    getData(item, index) {
      this.currentIndex = index;
      console.log(56, index)
      switch (item.name) {
        case "台风":
          this.getTyList();
          // this.$store.state['map/mapMenu'].isVisible ? this.currentIndex 
          break;
        case "雷达":
          this.getRader();
          break;
        case "浪场":
          this.getWave();
          break;
        case "云图":
          this.getCloud();
          break;
        case "风场":
          this.getWind();
          break;
      }
    },
  },
};
</script>

<style scoped>
.appMenu {
  width: 100%;
  height: 100%;
}

.weather_map {
  position: absolute;
  display: flex;
  right: 10px;
  top: 10px;
  z-index: 999;
  cursor: pointer;
}

.icon {
  display: flex;
  flex-direction: column;
}
</style>

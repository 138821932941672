<template>
  <div class="mapContainer" :style="{ width: '100%', height: '100%' }">
    <div :id="mapId" :class="['mapClass']">
      <div id="hidding"></div>
      <div v-if="map">
        <!-- 组件板块 -->
        <map-base ref="mapCom" :map="map" />
        <map-switch ref="mapSwitch" :is-show="false" :map="map" />
        <!-- <map-tool ref="mapTool" :map="map" /> -->
        <!-- <map-route ref="mapRoute" :map="map" /> -->
        <!-- <map-lonlat ref="mapLonlat" :isShow="true" :map="map" /> -->
        <!-- <map-time ref="mapTime" :map="map" /> -->
        <!-- <map-test ref="mapTest" :map="map" /> -->
        <map-weather ref="mapWeather" :map="map" />

        <div v-for="(data, index) in mapStore.config" :key="index">
          <component :is="data.component" :ref="data.ref" :map="map"
            ><slot
          /></component>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MapC",
  components: {},
  data() {
    return {
      mapStore: this.$store.getters.map,
      mapId: this.$mapValue.mapId, //地图容器id
      map: null, //地图对象
      // 地图数据量过大时，用于优化交互所用【startMove,drawToolMove,dragInfoMove】
      startMove: true, //缩放
      drawToolMove: true, //工具条
      dragInfoMove: true, //拖拽
      mapClickAllowed: true, //地图单击
      singleclickEvt: null, //地图单击事件
      movestartEvt: null, //地图开始移动事件
      moveendEvt: null, //地图结束移动事件
      menucontextEvt: null, //地图右击事件
      pointermoveEvt: null, //地图鼠标滑动事件

      touchTimeOut: "",
      touchTimeOutArray: [],
    };
  },
  provide() {
    return {
      setStartMoveLis: this.setStartMoveLis,
      setDrawToolMoveLis: this.setDrawToolMoveLis,
      setDragInfoMoveLis: this.setDragInfoMoveLis,
      getStartMoveLis: this.getStartMoveLis,
      getDrawToolMoveLis: this.getDrawToolMoveLis,
      getDragInfoMoveLis: this.getDragInfoMoveLis,
      setMapClickAllowed: this.setMapClickAllowed,
    };
  },
  watch: {},
  onLoad() {
    // console.log("地图主控页面");
  },
  mounted() {
    this.$nextTick(() => {
      this.initMap();
    });
  },
  methods: {
    setStartMoveLis(flag) {
      this.startMove = flag;
    },
    getStartMoveLis() {
      return this.startMove;
    },
    setDrawToolMoveLis(flag) {
      this.drawToolMove = flag;
    },
    getDrawToolMoveLis() {
      return this.drawToolMove;
    },
    setDragInfoMoveLis(flag) {
      this.dragInfoMove = flag;
    },
    getDragInfoMoveLis() {
      return this.dragInfoMove;
    },
    setMapClickAllowed(status) {
      this.mapClickAllowed = status;
    },
    getmapClickAllowed() {
      return this.mapClickAllowed;
    },
    //地图实时刷新
    getMapTiming() {
      const fun = () => {
        this.map.render();
        this.map.updateSize();
        this.map.setView(this.map.getView());
        if (this.mapRendeTime) clearTimeout(this.mapRendeTime);
        this.mapRendeTime = setTimeout(() => {
          fun();
        }, 10000);
      };
      fun();
    },
    // 初始化视图
    initView() {
      return new this.mapApi.View(
        Object.assign(this.$mapConfig.mapView, {
          center: this.mapApi.transform(
            this.$mapConfig.mapView.center,
            this.$mapConfig.mapProjection.from,
            this.$mapConfig.mapProjection.to
          ),
        })
      );
    },
    // 初始化控制器
    initControl() {
      return this.mapApi.defaultControls(this.$mapConfig.mapControl);
    },
    // 初始化交互
    initInteractions() {
      return this.mapApi.defaultsInteractions(this.$mapConfig.interactions);
    },
    // 初始化地图
    initMap() {
      this.map = new this.mapApi.Map({
        interactions: this.initInteractions(),
        target: this.mapId,
        view: this.initView(),
        controls: this.initControl(),
      });
      this.bindMapEvent();

      // 地图卡片自适应位置配置（待迁移）
      let size = this.map.getSize();
      let x = size[0];
      let y = size[1];
      let dis = (this.$mapValue.infoWinPosition.height =
        y / this.$mapValue.infoWinPosition.countY - 10);
      this.$mapValue.infoWinPosition.width =
        x / this.$mapValue.infoWinPosition.countX - 10;
      this.$mapValue.infoWinPosition.pixel[0] = [x / 2, y / 2];
      this.$mapValue.infoWinPosition.pixel[1] = [dis / 2, dis];
      this.$mapValue.infoWinPosition.pixel[2] = [x - dis * 2.5, dis];
      this.$mapValue.infoWinPosition.pixel[3] = [x - dis * 2.5, y - dis * 3];
      this.$mapValue.infoWinPosition.pixel[4] = [dis / 2, y - dis * 3];
    },
    removeMapEvent() {
      this.mapApi.unByKey(this.singleclickEvt);
      this.mapApi.unByKey(this.pointermoveEvt);
      this.mapApi.unByKey(this.movestartEvt);
      this.mapApi.unByKey(this.moveendEvt);
      this.menucontextEvt = this.map
        .getViewport()
        .removeEventListener("contextmenu", this.menuContextEvt);
    },
    bindMapEvent() {
      this.removeMapEvent();
      this.singleclickEvt = this.map.on("singleclick", this.singleClick);
      this.pointermoveEvt = this.map.on("pointermove", this.touchOnly);
      this.movestartEvt = this.map.on("movestart", this.moveStartEvt);
      this.moveendEvt = this.map.on("moveend", this.moveEndEvt);
      // this.menucontextEvt = this.map
      //   .getViewport()
      //   .addEventListener("contextmenu", this.menuContextEvt);
    },
    menuContextEvt(evt) {
      evt.preventDefault(); //屏蔽自带的右键事件
      var pixel = [evt.clientX, evt.clientY];
      let array = [];
      this.map.forEachFeatureAtPixel(pixel, (feature, layer) => {
        array.push([feature, layer ? layer.get("id") : ""]);
        if (array.length === 1) {
          this.$store.dispatch("map/mapContextMenu/setSelected", {
            pixel: pixel,
            isShow: true,
            feature: array[0][0],
            layerId: array[0][1],
          });
        }
      });
      if (array.length === 0)
        this.$store.dispatch("map/mapContextMenu/setSelected", {
          isShow: false,
        });
    },
    singleClick(evt) {
      // this.$store.dispatch("map/mapContextMenu/setSelected", {
      //   isShow: false,
      // });
      // this.$store.dispatch("map/mapPosition/setSelected", {
      //   type: -1,
      // });
      this.$refs.mapCom.removeFlashMarker();
      if (!this.$isMobile()) this.$refs.mapCom.closeInfoWinOver();
      if (
        !this.getStartMoveLis() ||
        !this.getDrawToolMoveLis() ||
        !this.getDragInfoMoveLis() ||
        !this.getmapClickAllowed()
      )
        return;
      this.mapStore.config.map((v) => {
        let ref = this.$refs[v.ref];
        if (ref && ref[0].singleClick) ref[0].singleClick(evt);
      });
      this.$refs.mapWeather.singleClick(evt);
      let coord = this.map.getCoordinateFromPixel(evt.pixel);
      this.getClickPointArray(coord);
    },
    touchOnlyForbitted() {
      // 当且悬浮只存在一个，多次悬浮则禁用
      // if (document.querySelector(".touchOnlyInfoWin")) return;
      if (!this.pointermoveEvt) return;
      if (
        !this.getStartMoveLis() ||
        !this.getDrawToolMoveLis() ||
        !this.getDragInfoMoveLis()
      )
        return;
      if (!this.touchTime) this.touchTime = new Date().getTime();
      if (new Date().getTime() - this.touchTime < 50) return;
      else this.touchTime = new Date().getTime();

      return 1;
    },
    touchOnly(evt) {
      if (!this.touchOnlyForbitted()) return;
      // if (!this.$isMobile()) this.$refs.mapCom.closeInfoWinOver();
      let touchLeaveUnFeature = true; //是否单纯悬浮地图（未悬浮要素）
      let array = [];
      this.map.forEachFeatureAtPixel(evt.pixel, (feature, layer) => {
        touchLeaveUnFeature = false;
        array.push([feature, layer ? layer.get("id") : ""]);
        if (array.length === 1) {
          let feature = array[0][0];
          let layerId = array[0][1];
          //执行及时悬浮操作
          this.mapStore.config.map((v) => {
            let ref = this.$refs[v.ref];
            if (ref && ref[0].touchOnly && v.isTouch && !v.isNotTimingTouch)
              ref[0].touchOnly(evt);
          });
          this.$refs.mapWeather.touchOnly(feature, layerId);

          // 渔船悬浮特殊处理（超过钟显示执行信息窗口，不然保留现有悬浮状态）
          //记录悬浮对象（当且未改变时或者初始化时记录）
          if (!this.touchTar)
            this.touchTar = feature.getGeometry().getCoordinates();
          //当前悬浮对象
          let coord = feature.getGeometry().getCoordinates();
          //如果钟之内改变悬浮对象，则悬浮操作终结
          if (this.touchTar[0] !== coord[0] || this.touchTar[1] !== coord[1]) {
            // console.log("终止此次悬浮操作");
            for (let i = this.touchTimeOutArray.length - 1; i >= 0; i--) {
              clearTimeout(this.touchTimeOutArray[i]);
            }
            this.touchTimeOutArray = [];
            // 改变悬浮对象
            this.touchTar = coord;
          }
          const fun = () => {
            //悬浮执行
            this.touchTimeOut = setTimeout(() => {
              if (
                this.touchTar[0] === coord[0] &&
                this.touchTar[1] === coord[1]
              ) {
                // 悬浮执行完毕，销毁记录的悬浮对象
                this.touchTar = "";
                //销毁悬浮定时
                for (let i = this.touchTimeOutArray.length - 1; i >= 0; i--) {
                  clearTimeout(this.touchTimeOutArray[i]);
                }
                this.touchTimeOutArray = [];
                // 销毁悬浮信息窗口（当且只保留一个悬浮窗口）
                if (!this.$isMobile()) this.$refs.mapCom.closeInfoWinOver();
                //执行延时悬浮操作
                this.mapStore.config.map((v) => {
                  let ref = this.$refs[v.ref];
                  if (
                    ref &&
                    ref[0].touchOnly &&
                    v.isTouch &&
                    v.isNotTimingTouch
                  )
                    ref[0].touchOnly(evt);
                });
              }
            }, this.$store.getters.mapShipItem.selected.touchTimeOut);
            //当且保留一次悬浮定时
            if (this.touchTimeOut && this.touchTimeOutArray.length === 0)
              this.touchTimeOutArray.push(this.touchTimeOut);
            else clearTimeout(this.touchTimeOut);
          };
          fun();
        }
      });
      if (touchLeaveUnFeature) {
        for (let i = this.touchTimeOutArray.length - 1; i >= 0; i--) {
          clearTimeout(this.touchTimeOutArray[i]);
        }
        this.touchTimeOutArray = [];
      }
    },
    restraintPointerMove() {
      this.pointermoveEvt = null;
      this.map.un("pointermove", this.touchOnly);
      // this.touchTar = "";
      //销毁悬浮定时
      for (let i = this.touchTimeOutArray.length - 1; i >= 0; i--) {
        clearTimeout(this.touchTimeOutArray[i]);
      }
      this.$refs.mapCom.closeInfoWinOver();
    },
    moveStartEvt() {
      this.restraintPointerMove();
    },
    moveEndEvt() {
      // this.pointermoveEvt = this.map.on("pointermove", this.touchOnly);
    },
    //获取地图单击点位
    getClickPointArray(coord) {
      if (!this.aaa) this.aaa = [];
      this.aaa.push(coord);
      // this.aaa.push({
      //   name: "" + (this.aaa.length + 1),
      //   longitude: coord[0],
      //   latitude: coord[1],
      //   rptTime: new Date().getTime(),
      //   type: this.aaa.length % 2 === 0 ? "1" : "2",
      // });
      console.log(this.aaa);
    },
  },
  beforeDestroy() {
    this.removeMapEvent();
  },
};
</script>

<style lang="scss" scoped>
.mapContainer {
  width: 100%;
  height: 100%;
  position: absolute;
}
.mapClass {
  width: 100%;
  height: 100%;
  position: relative;
}
#hidding {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 998;
  display: none;
  background-color: rgba(156, 163, 169, 0.5);
}
</style>
